import {
  Button,
  Card,
  DateSelect,
  FileUploader,
  Input,
  Select,
  TextArea
} from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CreateBeneficialOwnerInput,
  DwollaCustomer,
  EditBeneficialOwnerInput,
  InvestmentAccount
} from '__generated__';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { maxUploadSize } from 'utils/constants';
import { ssn, ssnMaskOptions } from 'utils/masks';

import {
  setDateSelectFieldProps,
  setFieldProps
} from '../../../../utils/formHelpers';
import { stateOptions } from '../../constants';
import { idTypeOptions } from '../../InvestmentAccounts/EditInvestmentAccount/Documents/constants';
import * as styles from '../BeneficialOwners.module.scss';
import { getEditResourceLink } from '../helpers';
import { beneficialOwnersSchema } from '../validation';

type FormValues = CreateBeneficialOwnerInput | EditBeneficialOwnerInput;

interface Props {
  defaultValues: DefaultValues<FormValues>;
  dwollaCustomer?: DwollaCustomer;
  form?: string;
  investmentAccount?: InvestmentAccount;
  onSubmit: (submitData: Partial<FormValues>) => unknown;
  submitting: boolean;
}

const BeneficialOwnerForm: React.FC<Props> = ({
  defaultValues,
  dwollaCustomer,
  form = 'add',
  investmentAccount,
  onSubmit,
  submitting
}) => {
  const heading = dwollaCustomer?.businessName || investmentAccount?.entityName;
  const subHeading =
    form === 'add' ? 'Add Beneficial Owner' : 'Edit Beneficial Owner';
  const submitText =
    form === 'add' ? 'Create Beneficial Owner' : 'Update Beneficial Owner';

  const editResourceLink = getEditResourceLink(
    investmentAccount?.id,
    dwollaCustomer?.id
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<Partial<FormValues>>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(beneficialOwnersSchema)
  });

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [reset, defaultValues]);

  return (
    <>
      <h1 className="contentNarrow" data-testid="resourceHeading">
        {heading}
      </h1>
      <Card className="contentNarrow">
        <h3>{subHeading}</h3>

        <form
          className="clearFix"
          data-testid="beneficialOwnerForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col md={6}>
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <Input {...setFieldProps(field, errors)} label="First Name" />
                )}
              />
            </Col>
            <Col md={6}>
              <Controller
                control={control}
                name="lastName"
                render={({ field }) => (
                  <Input {...setFieldProps(field, errors)} label="Last Name" />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <Input {...setFieldProps(field, errors)} label="Email" />
                )}
              />
            </Col>
            <Col md={6}>
              <Controller
                control={control}
                name="title"
                render={({ field }) => (
                  <Input {...setFieldProps(field, errors)} label="Title" />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Controller
                control={control}
                name="ssn"
                render={({ field }) => (
                  <Input
                    {...setFieldProps(field, errors)}
                    inputMaskOptions={ssnMaskOptions}
                    label="SSN"
                    mask={ssn}
                  />
                )}
              />
            </Col>
            <Col md={6}>
              <Controller
                control={control}
                name="dob"
                render={({ field }) => (
                  <DateSelect
                    {...setDateSelectFieldProps(field, errors)}
                    label="Date of Birth"
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Controller
                control={control}
                name="phone"
                render={({ field }) => (
                  <Input
                    {...setFieldProps(field, errors)}
                    label="Phone Number"
                    mask="(999) 999-9999 [ext 999999]"
                  />
                )}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={6}>
              <Controller
                control={control}
                name="address"
                render={({ field }) => (
                  <TextArea
                    {...setFieldProps(field, errors)}
                    label="Address Line 1"
                  />
                )}
              />
            </Col>
            <Col md={6}>
              <Controller
                control={control}
                name="address2"
                render={({ field }) => (
                  <TextArea
                    {...setFieldProps(field, errors)}
                    label="Address Line 2 (Optional)"
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Controller
                control={control}
                name="city"
                render={({ field }) => (
                  <Input {...setFieldProps(field, errors)} label="City" />
                )}
              />
            </Col>
            <Col md={6}>
              <Controller
                control={control}
                name="state"
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    label="State"
                    options={stateOptions}
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Controller
                control={control}
                name="postalCode"
                render={({ field }) => (
                  <Input
                    {...setFieldProps(field, errors)}
                    label="Postal Code"
                    mask="9{5}"
                  />
                )}
              />
            </Col>
          </Row>
          <hr />
          <h4>Identification Document</h4>
          <Row className="margin-row">
            <Col className="overflow-visible" md={6}>
              <Controller
                control={control}
                name="document.documentType"
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    label="Document Type"
                    options={idTypeOptions.map(key => ({
                      label: key.label,
                      value: key.value
                    }))}
                  />
                )}
              />
            </Col>
          </Row>
          <Row className="margin-row">
            <Col md={12}>
              <Controller
                control={control}
                name="document.file"
                render={({ field }) => (
                  <FileUploader
                    {...setFieldProps(field, errors)}
                    acceptedFileTypes={['JPG', 'PNG', 'PDF']}
                    existingFile={field.value}
                    maxSize={maxUploadSize}
                    onRemove={() => field.onChange(null)}
                    showImage
                    showLink
                    upload={(uploadData: File) => field.onChange(uploadData)}
                    uploading={submitting}
                  />
                )}
              />
            </Col>
          </Row>
          <div className={styles.actionItems}>
            <Link
              className="textLink underline"
              to={editResourceLink}
              type="button"
            >
              Cancel
            </Link>
            <Button className="floatRight" loading={submitting} type="submit">
              {submitText}
            </Button>
          </div>
        </form>
      </Card>
    </>
  );
};

export default BeneficialOwnerForm;
