import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MoveDwollaBalance from 'views/Accounts/AdminAccounts/MoveDwollaBalance';
import AddBeneficialOwner from 'views/Accounts/BeneficialOwners/AddBeneficialOwner/AddBeneficialOwner';
import EditBeneficialOwner from 'views/Accounts/BeneficialOwners/EditBeneficialOwner/EditBeneficialOwner';
import CreateInvestmentAccount from 'views/Accounts/InvestmentAccounts/CreateInvestmentAccount/CreateInvestmentAccount';
import OfferingDocuments from 'views/Offerings/OfferingDocuments/OfferingDocuments';
import InvestmentDetails from 'views/Offerings/OfferingInvestments/InvestmentDetails/InvestmentDetails';
import NewTransaction from 'views/Offerings/OfferingInvestments/InvestmentDetails/NewTransaction/NewTransaction';
import NewInvestment from 'views/Offerings/OfferingInvestments/NewInvestment/NewInvestment';
import OfferingInvestments from 'views/Offerings/OfferingInvestments/OfferingInvestments';
import RefundInvestment from 'views/Offerings/OfferingInvestments/RefundInvestment/RefundInvestment';
import ReinvestmentElections from 'views/Offerings/OfferingInvestments/ReinvestmentElections/ReinvestmentElections';
import InvestmentAccount from 'views/Users/User/InvestmentAccounts/InvestmentAccount';
import User from 'views/Users/User/User';

import Accounts from '../../../views/Accounts/Accounts';
import AddAdminAccount from '../../../views/Accounts/AdminAccounts/AddAdminAccount/AddAdminAccount';
import EditAdminAccount from '../../../views/Accounts/AdminAccounts/EditAdminAccount/EditAdminAccount';
import InvestmentAccountEdit from '../../../views/Accounts/InvestmentAccounts/EditInvestmentAccount/InvestmentAccountEdit';
import MoveDwollaBalances from '../../../views/Accounts/InvestmentAccounts/MoveDwollaBalances/MoveDwollaBalances';
import Configurations from '../../../views/Configurations/Configurations';
import DocumentDownload from '../../../views/DocumentDownload/DocumentDownload';
import Offering from '../../../views/Offerings/Offering/Offering';
import OfferingBankAccount from '../../../views/Offerings/OfferingBankAccount/OfferingBankAccount';
import Offerings from '../../../views/Offerings/Offerings';
import EditPeriod from '../../../views/Payments/EditPeriod/EditPeriod';
import NewPeriod from '../../../views/Payments/NewPeriod/NewPeriod';
import PaymentPeriods from '../../../views/Payments/PaymentPeriods/PaymentPeriods';
import Payments from '../../../views/Payments/Payments';
import PeriodAllocation from '../../../views/Payments/PeriodAllocation/PeriodAllocation';
import ReviewAllocation from '../../../views/Payments/ReviewAllocation/ReviewAllocation';
import RunTransactions from '../../../views/Payments/RunTransactions/RunTransactions';
import AddPostAuthor from '../../../views/Posts/PostAuthors/AddPostAuthor/AddPostAuthor';
import EditPostAuthor from '../../../views/Posts/PostAuthors/EditPostAuthor/EditPostAuthor';
import Posts from '../../../views/Posts/Posts';
import AddPost from '../../../views/Posts/Posts/AddPost/AddPost';
import EditPost from '../../../views/Posts/Posts/EditPost/EditPost';
import AddSponsor from '../../../views/Sponsors/AddSponsor/AddSponsor';
import EditSponsor from '../../../views/Sponsors/EditSponsor/EditSponsor';
import Sponsors from '../../../views/Sponsors/Sponsors';
import TaxTracker from '../../../views/TaxTracker/TaxTracker';
import AddUserCredit from '../../../views/Users/AddReferralCredit/AddReferralCredit';
import Users from '../../../views/Users/Users';
import Login from '../../Login/Login';
import Logout from '../../Logout/Logout';
import Layout from '../Layout';
import NoMatchingRoute from '../NoMatchingRoute/NoMatchingRoute';
import PageTitle from '../PageTitle/PageTitle';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<Login />} path="/login" />
      <Route
        element={
          <PageTitle title="Logging out...">
            <Logout />
          </PageTitle>
        }
        path="/logout"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Offerings">
              <Offerings />
            </PageTitle>
          </Layout>
        }
        path="/"
      />
      <Route element={<Navigate replace to="/" />} path="/offerings" />
      <Route
        element={
          <Layout>
            <PageTitle title="Offering Bank Account Information">
              <OfferingBankAccount />
            </PageTitle>
          </Layout>
        }
        path="offerings/:id/bank_account"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Investments">
              <OfferingInvestments />
            </PageTitle>
          </Layout>
        }
        path="offerings/:id/investments"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Add Investment">
              <NewInvestment />
            </PageTitle>
          </Layout>
        }
        path="offerings/:id/investments/new"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Investment Details">
              <InvestmentDetails />
            </PageTitle>
          </Layout>
        }
        path="offerings/:offeringId/investments/:id/details"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Refund Investment">
              <RefundInvestment />
            </PageTitle>
          </Layout>
        }
        path="offerings/:offeringId/investments/:id/refund"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="New Transaction">
              <NewTransaction />
            </PageTitle>
          </Layout>
        }
        path="offerings/:offeringId/investments/:id/transactions/new"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Reinvestment Elections">
              <ReinvestmentElections />
            </PageTitle>
          </Layout>
        }
        path="offerings/:offeringId/elections/:investmentId"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Edit Offering">
              <Offering />
            </PageTitle>
          </Layout>
        }
        path="offerings/:id/edit"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Offering Documents">
              <OfferingDocuments />
            </PageTitle>
          </Layout>
        }
        path="offerings/:id/documents"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Accounts">
              <Accounts />
            </PageTitle>
          </Layout>
        }
        path="/accounts"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Add Admin Account">
              <AddAdminAccount />
            </PageTitle>
          </Layout>
        }
        path="/accounts/new"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Edit Admin Account">
              <EditAdminAccount />
            </PageTitle>
          </Layout>
        }
        path="/accounts/:id/edit"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Add Admin Beneficial Owner">
              <AddBeneficialOwner />
            </PageTitle>
          </Layout>
        }
        path="/accounts/:dwolla_customer_id/beneficial_owners/new"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Edit Beneficial Owner">
              <EditBeneficialOwner />
            </PageTitle>
          </Layout>
        }
        path="/accounts/:dwolla_customer_id/beneficial_owners/:id/edit"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Move Dwolla Balance">
              <MoveDwollaBalance />
            </PageTitle>
          </Layout>
        }
        path="/accounts/:id/balance"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Edit Investment Account">
              <InvestmentAccountEdit />
            </PageTitle>
          </Layout>
        }
        path="/investment_accounts/:id/edit"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Add Account Beneficial Owner">
              <AddBeneficialOwner />
            </PageTitle>
          </Layout>
        }
        path="/investment_accounts/:investment_account_id/beneficial_owners/new"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Edit Beneficial Owner">
              <EditBeneficialOwner />
            </PageTitle>
          </Layout>
        }
        path="/investment_accounts/:investment_account_id/beneficial_owners/:id/edit"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Move Dwolla Balances">
              <MoveDwollaBalances />
            </PageTitle>
          </Layout>
        }
        path="/investment_accounts/:id/move_dwolla_balances"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Create Investment Account">
              <CreateInvestmentAccount />
            </PageTitle>
          </Layout>
        }
        path="/investment_accounts/new"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Users">
              <Users />
            </PageTitle>
          </Layout>
        }
        path="/users"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="User">
              <User />
            </PageTitle>
          </Layout>
        }
        path="/users/:id"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Account">
              <InvestmentAccount />
            </PageTitle>
          </Layout>
        }
        path="/users/:userId/accounts/:id"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Add Credit">
              <AddUserCredit />
            </PageTitle>
          </Layout>
        }
        path="/users/:id/add_credit"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Tax Tracker">
              <TaxTracker />
            </PageTitle>
          </Layout>
        }
        path="/tax_tracker"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Sponsors">
              <Sponsors />
            </PageTitle>
          </Layout>
        }
        path="/sponsors"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Add Sponsor">
              <AddSponsor />
            </PageTitle>
          </Layout>
        }
        path="/sponsors/new"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Edit Sponsor">
              <EditSponsor />
            </PageTitle>
          </Layout>
        }
        path="/sponsors/:id"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Posts">
              <Posts />
            </PageTitle>
          </Layout>
        }
        path="/posts"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Add Post">
              <AddPost />
            </PageTitle>
          </Layout>
        }
        path="/posts/new"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Edit Post">
              <EditPost />
            </PageTitle>
          </Layout>
        }
        path="/posts/:id/edit"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Add Post Author">
              <AddPostAuthor />
            </PageTitle>
          </Layout>
        }
        path="/post_authors/new"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Edit Post Author">
              <EditPostAuthor />
            </PageTitle>
          </Layout>
        }
        path="/post_authors/:id/edit"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Payments">
              <Payments />
            </PageTitle>
          </Layout>
        }
        path="/payments"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Transactions Schedule">
              <PaymentPeriods />
            </PageTitle>
          </Layout>
        }
        path="/payments/schedule/:offeringId/transactions"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="New Period">
              <NewPeriod />
            </PageTitle>
          </Layout>
        }
        path="/payments/schedule/:offeringId/periods/new"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Edit Period">
              <EditPeriod />
            </PageTitle>
          </Layout>
        }
        path="/payments/schedule/:offeringId/periods/:periodId/edit"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Period Allocation">
              <PeriodAllocation />
            </PageTitle>
          </Layout>
        }
        path="/payments/schedule/:offeringId/periods/:periodId/allocate"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Review Period Allocation">
              <ReviewAllocation />
            </PageTitle>
          </Layout>
        }
        path="/payments/schedule/:offeringId/periods/:periodId/review"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Run Transactions">
              <RunTransactions />
            </PageTitle>
          </Layout>
        }
        path="/payments/transactions/:offeringId/run"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Configurations">
              <Configurations />
            </PageTitle>
          </Layout>
        }
        path="/configuration"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Configurations">
              <Configurations />
            </PageTitle>
          </Layout>
        }
        path="/configuration/:page"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Document Download">
              <DocumentDownload />
            </PageTitle>
          </Layout>
        }
        path="/documents/:id/download"
      />
      <Route
        element={
          <Layout>
            <PageTitle title="Nothing Found">
              <NoMatchingRoute />
            </PageTitle>
          </Layout>
        }
        path="*"
      />
    </Routes>
  );
};

export default AppRoutes;
