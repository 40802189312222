import { BeneficialOwner, EditBeneficialOwnerInput } from '__generated__';

export const getBaseUrl = (
  investmentAccountId?: string,
  dwollaCustomerId?: string
): null | string => {
  if (investmentAccountId) {
    return `/investment_accounts/${investmentAccountId}`;
  } else if (dwollaCustomerId) {
    return `/accounts/${dwollaCustomerId}`;
  }
  return null;
};

export const getEditResourceLink = (
  investmentAccountId?: string,
  dwollaCustomerId?: string
): string => {
  return `${getBaseUrl(investmentAccountId, dwollaCustomerId)}/edit`;
};

export const getDefaultValues = (beneficialOwner?: BeneficialOwner) => {
  const fields: EditBeneficialOwnerInput = {
    address: beneficialOwner?.address || '',
    address2: beneficialOwner?.address2 || '',
    beneficialOwnerId: beneficialOwner?.id || '',
    city: beneficialOwner?.city || '',
    dob: beneficialOwner?.dob || '',
    document:
      (beneficialOwner?.document && {
        documentType: beneficialOwner.document.type,
        file: {
          name: beneficialOwner.document.filename,
          url: beneficialOwner.document.url
        }
      }) ||
      {},
    email: beneficialOwner?.email || '',
    firstName: beneficialOwner?.firstName || '',
    lastName: beneficialOwner?.lastName || '',
    phone: beneficialOwner?.phone || '',
    postalCode: beneficialOwner?.postalCode || '',
    ssn: beneficialOwner?.ssn || '',
    state: beneficialOwner?.state || '',
    title: beneficialOwner?.title || ''
  };

  return fields;
};
