import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  CreateBeneficialOwnerInput,
  DwollaCustomer,
  InvestmentAccount,
  namedOperations,
  useCreateBeneficialOwnerMutation,
  useGetDwollaCustomerQuery,
  useGetInvestmentAccountQuery
} from '../../../../__generated__';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';
import BeneficialOwnerForm from '../components/BeneficialOwnerForm';
import { getEditResourceLink } from '../helpers';

const toastMessages = {
  error: 'An error occurred while attempting to add the beneficial owner',
  loading: 'Adding beneficial owner',
  success: 'Beneficial owner added'
};

const defaultValues = {
  address: '',
  address2: '',
  city: '',
  dob: '',
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  postalCode: '',
  ssn: '',
  title: ''
};

const AddBeneficialOwner: React.FC = () => {
  const navigate = useNavigate();

  const {
    dwolla_customer_id: dwollaCustomerId,
    investment_account_id: investmentAccountId
  } = useParams();

  const { data: dwollaCustomerData, loading } = useGetDwollaCustomerQuery({
    variables: {
      dwollaCustomerId
    }
  });

  const dwollaCustomer = dwollaCustomerData?.dwollaCustomer
    ?.dwollaCustomer as DwollaCustomer;

  const { data: investmentAccountData } = useGetInvestmentAccountQuery({
    variables: {
      investmentAccountId
    }
  });

  const investmentAccount = investmentAccountData?.investmentAccount
    ?.investmentAccount as InvestmentAccount;

  const [createBeneficialOwner, createBeneficialOwnerState] =
    useCreateBeneficialOwnerMutation();

  const submitting = createBeneficialOwnerState.loading || loading;

  const editResourceLink = getEditResourceLink(
    investmentAccountId,
    dwollaCustomerId
  );

  const onBeneficialOwnerSubmit = (
    submitData: Partial<CreateBeneficialOwnerInput>
  ) => {
    submitData.dwollaCustomerId = dwollaCustomerId;
    submitData.investmentAccountId = investmentAccountId;

    callMutationWithToastMessages(createBeneficialOwner, toastMessages, {
      refetchQueries: [namedOperations.Query.getDwollaCustomer],
      variables: {
        beneficialOwnerInput: submitData
      }
    }).then(() => {
      navigate(editResourceLink);
    });
  };

  return (
    <BeneficialOwnerForm
      defaultValues={defaultValues}
      dwollaCustomer={dwollaCustomer}
      investmentAccount={investmentAccount}
      onSubmit={onBeneficialOwnerSubmit}
      submitting={submitting}
    />
  );
};

export default AddBeneficialOwner;
