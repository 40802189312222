import * as yup from 'yup';

import { ageOver18, date, email, yupString } from '../../../utils/validations';

export const beneficialOwnersSchema = yup.object().shape({
  address: yupString.required('Address is required'),
  address2: yupString,
  city: yupString.required('City is required'),
  dob: date.required('Date of birth is required').test(ageOver18),
  document: yup.object().shape({
    documentType: yupString.when('file', {
      is: (val: File | null) => val,
      then: () => yupString.required('Document Type is Required')
    })
  }),
  email: email,
  firstName: yupString.required('First name is required'),
  lastName: yupString.required('Last name is required'),
  phone: yupString.required('Phone number is required'),
  postalCode: yupString.required('Postal code is required'),
  ssn: yupString.required('Social security number is required'),
  state: yupString.required('State is required'),
  title: yupString.required('Title is required')
});
