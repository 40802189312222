import { Button, Card } from '@equitymultiple/react-eui';
import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link, useParams } from 'react-router-dom';

import {
  DwollaCustomer,
  useGetDwollaCustomerQuery
} from '../../../../__generated__';
import BeneficialOwners from '../../BeneficialOwners/BeneficialOwners';
import * as styles from '../AdminAccounts.module.scss';
import DeactivateDwollaCustomerModal from '../DeactivateAdminAccountModal';
import BankAccounts from './BankAccounts';
import EditDwollaCustomer from './EditDwollaCustomer';
import IdentityDocument from './IdentityDocument';

const EditAdminAccount: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { id } = useParams();

  const { data, loading } = useGetDwollaCustomerQuery({
    variables: {
      dwollaCustomerId: id as string
    }
  });

  const dwollaCustomer = data?.dwollaCustomer?.dwollaCustomer as DwollaCustomer;

  return (
    <>
      <DeactivateDwollaCustomerModal
        isModalOpen={isModalOpen}
        selectedDwollaCustomer={dwollaCustomer as DwollaCustomer}
        setIsModalOpen={setIsModalOpen}
      />
      <h2 data-testid="editAdminAccount">Edit Admin Account</h2>
      <Card>
        <div className={styles.formTop}>
          <Link to="/accounts">&lt; Back</Link>

          <Button
            onClick={() => setIsModalOpen(true)}
            type="button"
            variant="orange"
          >
            Deactivate
          </Button>
        </div>
        <h3>Basic Details</h3>
        <div data-testid="basicDetails">
          <div className={styles.defaultValues}>
            <strong>Business Name:</strong>
            {dwollaCustomer?.businessName || <Skeleton />}
          </div>
          {dwollaCustomer?.doingBusinessAs && (
            <div className={styles.defaultValues}>
              <strong>Doing Business As:</strong>
              {dwollaCustomer?.doingBusinessAs}
            </div>
          )}
          <div className={styles.defaultValues}>
            <strong>Email:</strong> {dwollaCustomer?.email || <Skeleton />}
          </div>
          <div className={styles.defaultValues}>
            <strong>Status:</strong> {dwollaCustomer?.status || <Skeleton />}
          </div>
          <div className={styles.defaultValues}>
            <strong>Type:</strong> {dwollaCustomer?.type || <Skeleton />}
          </div>
          <div className={styles.defaultValues}>
            <strong>Created By:</strong>
            {dwollaCustomer?.createdBy || <Skeleton />}
          </div>
        </div>

        <EditDwollaCustomer dwollaCustomer={dwollaCustomer} loading={loading} />

        <IdentityDocument dwollaCustomer={dwollaCustomer} loading={loading} />

        {dwollaCustomer?.id && (
          <BeneficialOwners dwollaCustomerId={dwollaCustomer.id} />
        )}

        <hr />
        <BankAccounts dwollaCustomer={dwollaCustomer} loading={loading} />
      </Card>
    </>
  );
};

export default EditAdminAccount;
