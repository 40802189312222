import { Button, Table } from '@equitymultiple/react-eui';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetBeneficialOwnersQuery } from '../../../__generated__';
import * as styles from './BeneficialOwners.module.scss';
import BeneficialOwnersActions from './components/BeneficialOwnersActions';
import { getBaseUrl } from './helpers';

const columnHeaders = [
  'ID',
  'Title',
  'First Name',
  'Last Name',
  'Email',
  'Status',
  'Actions'
];

export interface Props {
  dwollaCustomerId?: string;
  investmentAccountId?: string;
}

const BeneficialOwners: React.FC<Props> = ({
  dwollaCustomerId,
  investmentAccountId
}) => {
  const navigate = useNavigate();

  const { data, loading } = useGetBeneficialOwnersQuery({
    variables: {
      dwollaCustomerId: dwollaCustomerId,
      investmentAccountId: investmentAccountId
    }
  });

  const beneficialOwners = data?.beneficialOwners?.beneficialOwners || [];

  const handleAddBeneficialOwner = () => {
    const baseUrl = getBaseUrl(investmentAccountId, dwollaCustomerId);
    navigate(`${baseUrl}/beneficial_owners/new`);
  };

  const getRows = () => {
    return beneficialOwners && beneficialOwners.length > 0
      ? beneficialOwners.map(beneficialOwner => {
          const { email, firstName, id, lastName, status, title } =
            beneficialOwner;

          return {
            cells: [
              id,
              title,
              firstName,
              lastName,
              email,
              status,
              <BeneficialOwnersActions
                beneficialOwnerId={id as string}
                dwollaCustomerId={dwollaCustomerId as string}
                investmentAccountId={investmentAccountId as string}
                key={id}
              />
            ]
          };
        })
      : [{ cells: ['No beneficial owner found'] }];
  };

  return (
    <>
      <hr />
      <div className={styles.headerItems}>
        <h3>Beneficial Owners</h3>
        <Button className="floatRight" onClick={handleAddBeneficialOwner}>
          New Beneficial Owner
        </Button>
      </div>
      <Table
        className={styles.tableActions}
        columnHeaders={columnHeaders}
        data-testid="beneficialOwners"
        loading={loading}
        loadingRows={2}
        rows={getRows()}
      />
    </>
  );
};

export default BeneficialOwners;
