import { ToggleableTooltip } from '@equitymultiple/react-eui';
import React from 'react';
import { Link } from 'react-router-dom';

import { useDeleteBeneficialOwnerMutation } from '../../../../__generated__';
import { deleteObjectFromCache } from '../../../../utils/apolloCacheHelpers';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';
import { getBaseUrl } from '../helpers';

export interface MutationVariablesIds {
  beneficialOwnerId: string;
  dwollaCustomerId?: string;
  investmentAccountId?: string;
}

const toastMessages = {
  error: 'An error occurred while attempting to delete the beneficial owner',
  loading: 'Deleting beneficial owner',
  success: 'Beneficial owner deleted'
};

const BeneficialOwnersActions: React.FC<MutationVariablesIds> = ({
  beneficialOwnerId,
  dwollaCustomerId,
  investmentAccountId
}) => {
  const [deleteBeneficialOwner] = useDeleteBeneficialOwnerMutation();

  const handleDeleteBeneficialOwner = () => {
    const mutationVariables = {
      beneficialOwnerId,
      dwollaCustomerId
    };

    callMutationWithToastMessages(deleteBeneficialOwner, toastMessages, {
      update(cache) {
        deleteObjectFromCache(cache, 'BeneficialOwner', beneficialOwnerId);
      },
      variables: mutationVariables
    });
  };

  const editBeneficialOwnerLink = (): string => {
    const baseUrl = getBaseUrl(investmentAccountId, dwollaCustomerId);
    return `${baseUrl}/beneficial_owners/${beneficialOwnerId}/edit`;
  };

  return (
    <ToggleableTooltip
      data-testid="beneficialOwnerActions"
      placement="leftStart"
    >
      <Link to={editBeneficialOwnerLink()}>Edit</Link>
      <button
        className="textLink underline"
        data-testid={`beneficialOwnerDeleteButton-${beneficialOwnerId}`}
        onClick={handleDeleteBeneficialOwner}
        type="button"
      >
        Delete
      </button>
    </ToggleableTooltip>
  );
};

export default BeneficialOwnersActions;
