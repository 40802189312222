import React from 'react';
import { useParams } from 'react-router-dom';

import {
  BeneficialOwner,
  DwollaCustomer,
  EditBeneficialOwnerInput,
  InvestmentAccount,
  namedOperations,
  useEditBeneficialOwnerMutation,
  useGetBeneficialOwnerQuery,
  useGetDwollaCustomerQuery,
  useGetInvestmentAccountQuery
} from '../../../../__generated__';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';
import BeneficialOwnerForm from '../components/BeneficialOwnerForm';
import { getDefaultValues } from '../helpers';

const toastMessages = {
  error: 'An error occurred while attempting to update the beneficial owner',
  loading: 'Updating beneficial owner',
  success: 'Beneficial owner updated'
};

const EditBeneficialOwner: React.FC = () => {
  const {
    dwolla_customer_id: dwollaCustomerId,
    id,
    investment_account_id: investmentAccountId
  } = useParams();

  const { data: dwollaCustomerData } = useGetDwollaCustomerQuery({
    variables: {
      dwollaCustomerId
    }
  });

  const dwollaCustomer = dwollaCustomerData?.dwollaCustomer
    ?.dwollaCustomer as DwollaCustomer;

  const { data: investmentAccountData } = useGetInvestmentAccountQuery({
    variables: {
      investmentAccountId
    }
  });

  const investmentAccount = investmentAccountData?.investmentAccount
    ?.investmentAccount as InvestmentAccount;

  const { data } = useGetBeneficialOwnerQuery({
    variables: {
      beneficialOwnerId: id,
      dwollaCustomerId,
      investmentAccountId
    }
  });

  const beneficialOwner = data?.beneficialOwner
    ?.beneficialOwner as BeneficialOwner;

  const defaultValues = getDefaultValues(beneficialOwner);

  const [editBeneficialOwner, editBeneficialOwnerState] =
    useEditBeneficialOwnerMutation();

  const submitting = editBeneficialOwnerState.loading;

  const onBeneficialOwnerSubmit = (
    submitData: Partial<EditBeneficialOwnerInput>
  ) => {
    if (dwollaCustomerId) submitData.dwollaCustomerId = dwollaCustomerId;
    if (investmentAccountId)
      submitData.investmentAccountId = investmentAccountId;

    const idDocumentUpdated = submitData.document?.file instanceof File;
    if (!idDocumentUpdated) delete submitData.document;

    callMutationWithToastMessages(editBeneficialOwner, toastMessages, {
      refetchQueries: [namedOperations.Query.getDwollaCustomer],
      variables: {
        beneficialOwnerInput: submitData
      }
    });
  };

  return (
    <BeneficialOwnerForm
      defaultValues={defaultValues}
      dwollaCustomer={dwollaCustomer}
      form="edit"
      investmentAccount={investmentAccount}
      onSubmit={onBeneficialOwnerSubmit}
      submitting={submitting}
    />
  );
};

export default EditBeneficialOwner;
