import {
  Checkbox,
  DateSelect,
  FileUploader,
  Input,
  Select,
  TextArea,
  Tooltip
} from '@equitymultiple/react-eui';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValue,
  UseFormSetValue,
  UseWatchProps
} from 'react-hook-form';

import {
  Offering,
  OfferingApproach,
  OfferingInput
} from '../../../__generated__';
import RichEditor from '../../../components/RichEditor/RichEditor';
import {
  setCheckboxFieldProps,
  setDateSelectFieldProps,
  setFieldProps
} from '../../../utils/formHelpers';
import {
  numberMaskOptions,
  percentageMask,
  percentageMaskNumberOnUnMask,
  percentageMaskOptions,
  percentageMaskRange,
  percentageMaskRangeOptions,
  rangeMaskOptions
} from '../../../utils/masks';
import * as styles from '../Offering.module.scss';
import {
  annualReturnLabelOptions,
  assetTypeOptions,
  distributionFrequencyByValue,
  interestDayCountConventionOptions,
  investmentStrategyOptions,
  offeringApproachByValue,
  offeringPillarOptions,
  offeringStrategyOptions,
  offeringTypeOptions,
  originationFeePaidByOptions,
  preferredReturnLabelOptions,
  projectStageOptions,
  termPeriodByValue
} from './constants';
interface Props {
  control: Control<OfferingInput>;
  errors: FieldErrors<OfferingInput>;
  offering: Offering;
  setValue: UseFormSetValue<OfferingInput>;
  useWatch: (args: UseWatchProps<OfferingInput>) => FieldValue<OfferingInput>[];
}

const investmentIncrementPartialSharesInputMaskOptions = {
  ...numberMaskOptions,
  digits: 4
};

const OfferingFields: React.FC<Props> = ({
  control,
  errors,
  offering,
  setValue,
  useWatch
}) => {
  const [
    confidentialityRequired,
    customInstructionsEnabled,
    firstTimeInvestorMinimumAmount,
    investmentIncrement,
    partialShares,
    showLtc
  ] = useWatch({
    control,
    name: [
      'confidentialityRequired',
      'customInstructionsEnabled',
      'firstTimeInvestorMinimumAmount',
      'investmentIncrement',
      'partialShares',
      'showLtc'
    ]
  });

  return (
    <>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="alternateLocation"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Alternate Location Name"
              />
            )}
          />
        </Col>
      </Row>
      <hr />
      <h3>Offering Details</h3>
      <Row>
        <Col lg={4} md={6}>
          <Input disabled label="Offering Title" value={offering.title} />
        </Col>
        <Col lg={4} md={6}>
          <Input
            disabled
            dollarMask
            id="capitalRequest"
            inputMaskOptions={numberMaskOptions}
            label="Capital Request"
            name="capitalRequest"
            value={offering.capitalRequest || ''}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="sortWeight"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Offering Card Sort Weight"
                type="number"
              />
            )}
          />
        </Col>

        <Col lg={4} md={6}>
          <div className="margin20">
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <TextArea
                  {...field}
                  content={field.value as string}
                  errorMessage={
                    errors[field.name] && errors[field.name].message
                  }
                  helperText="The description displayed on highlighted offering cards"
                  helperTextAlwaysVisible
                  label="Description"
                />
              )}
            />
          </div>
        </Col>
      </Row>
      <hr />
      <h3>How are we marketing this offering?</h3>
      <Row>
        <Col lg={4} md={6}>
          <Input
            disabled
            id="paymentStatus"
            label="Payment Status"
            name="paymentStatus"
            value={offering.paymentStatus || ''}
          />
        </Col>
        <Col lg={4} md={6}>
          <Input
            disabled
            id="performanceStatus"
            label="Performance Status"
            name="performanceStatus"
            value={offering.performanceStatus || ''}
          />
        </Col>
      </Row>
      <Row>
        <Col data-testid="offeringApproach" lg={4} md={6}>
          <Input
            disabled
            id="offeringApproach"
            label="Offering Approach"
            name="offeringApproach"
            value={offeringApproachByValue(offering.offeringApproach)}
          />

          {offering.offeringApproach === OfferingApproach.Fund && (
            <Checkbox
              checked={offering.perpetualFund === true}
              className="margin20"
              disabled
              id="perpetualFundChkbox"
              label="Perpetual"
              name="perpetualFundChkbox"
            />
          )}
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="pillar"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Pillar"
                onChange={e => field.onChange(e)}
                options={offeringPillarOptions}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col data-testid="offeringStrategy" lg={4} md={6}>
          <Controller
            control={control}
            name="offeringStrategy"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Offering Strategy"
                multi
                onChange={val => field.onChange(val)}
                options={offeringStrategyOptions}
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="offeringType"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Offering Type"
                onChange={e => {
                  setValue('subtype', null);
                  field.onChange(e);
                }}
                options={offeringTypeOptions}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="investmentStrategy"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Investment Strategy"
                onChange={e => field.onChange(e)}
                options={investmentStrategyOptions}
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="assetType"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Asset Type"
                onChange={e => field.onChange(e)}
                options={assetTypeOptions}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="projectStage"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Project Stage"
                onChange={e => field.onChange(e)}
                options={projectStageOptions}
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Input
            disabled
            id="distributionFrequency"
            label="Distribution Frequency"
            name="distributionFrequency"
            value={distributionFrequencyByValue(offering.distributionFrequency)}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="interestDayCountConvention"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Interest Day Count Convention"
                onChange={e => field.onChange(e)}
                options={interestDayCountConventionOptions}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Input
            disabled
            dollarMask
            id="dealSize"
            inputMaskOptions={numberMaskOptions}
            label="Deal Size"
            name="dealSize"
            value={offering.dealSize || ''}
          />
        </Col>
        <Col lg={4} md={6}>
          <Input
            disabled
            dollarMask
            id="unitCost"
            inputMaskOptions={numberMaskOptions}
            label="Unit Cost"
            name="unitCost"
            value={offering.unitCost || ''}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={8} md={12}>
          <Controller
            control={control}
            name="crossClosingUnitCost"
            render={({ field }) => (
              <Checkbox
                {...setCheckboxFieldProps(field, errors)}
                className="margin20"
                label={
                  <>
                    <span>
                      Allow a lower unit cost on subsequent closings for those
                      investors that have already participated.
                    </span>
                    <Tooltip
                      className="inlineTooltip"
                      infoIcon
                      tooltipContent="Selecting this option will allow previously participating investors that have already met the minimum investment amount, to make investments equal to the unit cost set on the offering."
                    />
                  </>
                }
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="investmentIncrement"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                dollarMask
                inputMaskOptions={
                  partialShares
                    ? investmentIncrementPartialSharesInputMaskOptions
                    : numberMaskOptions
                }
                label="Investment Increment"
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="firstTimeInvestorMinimumAmount"
            render={({ field }) => {
              return (
                <Input
                  {...setFieldProps(field, errors)}
                  dollarMask
                  inputMaskOptions={
                    partialShares
                      ? investmentIncrementPartialSharesInputMaskOptions
                      : numberMaskOptions
                  }
                  label="First Time Investor Minimum Amount"
                />
              );
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="partialShares"
            render={({ field }) => (
              <Checkbox
                {...setCheckboxFieldProps(field, errors)}
                className="margin20"
                label="Allow Partial Shares"
                onChange={e => {
                  field.onChange(e);
                  if (
                    investmentIncrement &&
                    (investmentIncrement as number) % 1 !== 0
                  ) {
                    setValue(
                      'investmentIncrement',
                      Math.trunc(investmentIncrement as number)
                    );
                    if (firstTimeInvestorMinimumAmount) {
                      setValue(
                        'firstTimeInvestorMinimumAmount',
                        Math.trunc(firstTimeInvestorMinimumAmount as number)
                      );
                    }
                  }
                }}
              />
            )}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col lg={4} md={6}>
          <Input
            disabled
            id="term"
            label="Target Hold"
            name="term"
            type="number"
            value={offering.term || ''}
          />
        </Col>
        <Col data-testid="termPeriod" lg={4} md={6}>
          <Input
            disabled
            id="termPeriod"
            label="Target Hold Period"
            name="termPeriod"
            value={termPeriodByValue(offering.termPeriod)}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="redemptionPeriod"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Redemption Period"
              />
            )}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col lg={4} md={6}>
          <Input
            disabled
            id="equityMultiple"
            label="Equity Multiple"
            name="equityMultiple"
            value={offering.equityMultiple || ''}
          />
        </Col>
        <Col lg={4} md={6}>
          <Input
            disabled
            id="irr"
            label="Target Investor IRR"
            name="irr"
            value={offering.irr || ''}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="annualReturnLabel"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Annual Return Type"
                onChange={e => field.onChange(e)}
                options={annualReturnLabelOptions}
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="projectedAnnualReturn"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                inputMaskOptions={{
                  ...percentageMaskRangeOptions,
                  ...rangeMaskOptions
                }}
                label="Annual Return Value"
                mask={percentageMaskRange}
              />
            )}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="originationFeePaidBy"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Origination Fee Paid By"
                onChange={e => field.onChange(e)}
                options={originationFeePaidByOptions}
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="originationFeeRate"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                inputMaskOptions={{
                  ...percentageMaskOptions,
                  ...numberMaskOptions,
                  onUnMask: percentageMaskNumberOnUnMask
                }}
                label="Origination Fee Rate"
                mask={percentageMask}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="referralBonusRate"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                inputMaskOptions={{
                  ...percentageMaskOptions,
                  ...numberMaskOptions,
                  onUnMask: percentageMaskNumberOnUnMask
                }}
                label="Referral Bonus Rate"
                mask={percentageMask}
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="assetManagementRate"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                inputMaskOptions={{
                  ...percentageMaskOptions,
                  ...numberMaskOptions,
                  onUnMask: percentageMaskNumberOnUnMask
                }}
                label="Asset Management Rate"
                mask={percentageMask}
              />
            )}
          />
        </Col>
      </Row>
      <hr />
      <h4>Required for Preferred Equity Offerings</h4>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="preferredReturnLabel"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Preferred Return Label"
                onChange={e => field.onChange(e)}
                options={preferredReturnLabelOptions}
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Input
            disabled
            id="totalPreferredReturn"
            inputMaskOptions={percentageMaskOptions}
            label="Preferred Return Value"
            mask={percentageMask}
            name="totalPreferredReturn"
            value={offering.totalPreferredReturn || ''}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Input
            disabled
            id="currentReturn"
            inputMaskOptions={percentageMaskOptions}
            label="Current Return"
            mask={percentageMask}
            name="currentReturn"
            value={offering.currentReturn || ''}
          />
        </Col>
      </Row>
      <hr />
      <h4>Required for Debt Offerings</h4>
      <Row>
        <Col lg={4} md={6}>
          <Input
            disabled
            id="rate"
            inputMaskOptions={percentageMaskOptions}
            label="Rate"
            mask={percentageMask}
            name="rate"
            value={offering.rate || ''}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="lienPosition"
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Lien Position" />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="ltv"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                inputMaskOptions={percentageMaskOptions}
                label={showLtc ? 'LTC' : 'LTV'}
                mask={percentageMask}
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="showLtc"
            render={({ field }) => (
              <Checkbox
                {...setCheckboxFieldProps(field, errors)}
                label="Show as LTC"
              />
            )}
          />
        </Col>
      </Row>
      <h6>Borrower Payment Dependent Note Fields</h6>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="debtMaturityDate"
            render={({ field }) => (
              <DateSelect
                {...setDateSelectFieldProps(field, errors)}
                anyDate
                label="Debt Maturity Date"
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="debtExtensionRate"
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                inputMaskOptions={{
                  ...percentageMaskOptions,
                  ...numberMaskOptions,
                  onUnMask: percentageMaskNumberOnUnMask
                }}
                label={'Debt Extension Rate'}
                mask={percentageMask}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            control={control}
            name="appendBpdn"
            render={({ field }) => (
              <Checkbox
                {...setCheckboxFieldProps(field, errors)}
                label="Append Borrower Payment Dependent Note Addendum to Subscription Agreement"
              />
            )}
          />
        </Col>
      </Row>
      <hr />
      <p>Offering Card Image</p>
      <Row>
        <Col id="cardImage" lg={6} md={8}>
          <Controller
            control={control}
            name="cardImage"
            render={({ field }) => (
              <FileUploader
                {...field}
                acceptedFileTypes={['JPG', 'PNG']}
                errorMessage={errors?.cardImage?.message as string}
                existingFile={field.value}
                helperText="Recommended dimensions are 450x263. Larger images are acceptable as long as they are roughly a 1.7:1 aspect ratio."
                onRemove={() => field.onChange(null)}
                showImage
                upload={(uploadData: File) => field.onChange(uploadData)}
              />
            )}
          />
        </Col>
      </Row>
      <hr />
      <div className={styles.checkboxList}>
        <Controller
          control={control}
          name="confidentialityRequired"
          render={({ field }) => (
            <Checkbox
              {...setCheckboxFieldProps(field, errors)}
              label="Confidentiality Required"
            />
          )}
        />
        {confidentialityRequired && (
          <Row>
            <Col lg={8}>
              <div className={styles.checkboxSubFields}>
                <p>Confidentiality Agreement</p>
                <Controller
                  control={control}
                  name="confidentialityStatus"
                  render={({ field }) => (
                    <RichEditor
                      {...field}
                      content={field.value as string}
                      errorMessage={
                        errors[field.name] && errors[field.name].message
                      }
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
        )}
        <Controller
          control={control}
          name="disableCard"
          render={({ field }) => (
            <Checkbox
              {...setCheckboxFieldProps(field, errors)}
              label="Disable offering card link"
            />
          )}
        />
        <Controller
          control={control}
          name="customInstructionsEnabled"
          render={({ field }) => (
            <Checkbox
              {...setCheckboxFieldProps(field, errors)}
              label="Enable custom instructions"
            />
          )}
        />
        {customInstructionsEnabled && (
          <Row>
            <Col lg={8}>
              <div className={styles.checkboxSubFields}>
                <p>Custom Funding Instructions</p>
                <div className="margin10">
                  <Controller
                    control={control}
                    name="customFundingInstructions"
                    render={({ field }) => (
                      <RichEditor
                        {...field}
                        content={field.value as string}
                        errorMessage={
                          errors[field.name] && errors[field.name].message
                        }
                      />
                    )}
                  />
                </div>
                <p>Next Steps</p>

                <Controller
                  control={control}
                  name="customFundingNextSteps"
                  render={({ field }) => (
                    <RichEditor
                      {...field}
                      content={field.value as string}
                      errorMessage={
                        errors[field.name] && errors[field.name].message
                      }
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
        )}
        <Controller
          control={control}
          name="disableWaitlistPledges"
          render={({ field }) => (
            <Checkbox
              {...setCheckboxFieldProps(field, errors)}
              label="Disable waitlist pledges"
            />
          )}
        />
        <Controller
          control={control}
          name="showProgress"
          render={({ field }) => (
            <Checkbox
              {...setCheckboxFieldProps(field, errors)}
              label="Show Progress Bar"
            />
          )}
        />
        <Controller
          control={control}
          name="showViewCount"
          render={({ field }) => (
            <Checkbox
              {...setCheckboxFieldProps(field, errors)}
              label="Show View Count"
            />
          )}
        />
        <Controller
          control={control}
          name="showInvestorPacket"
          render={({ field }) => (
            <Checkbox
              {...setCheckboxFieldProps(field, errors)}
              label="Show investor packet link in place of return"
            />
          )}
        />
        <Controller
          control={control}
          name="acceptsCredits"
          render={({ field }) => (
            <Checkbox
              {...setCheckboxFieldProps(field, errors)}
              label="Accepts credits"
            />
          )}
        />
        <Controller
          control={control}
          name="hidden"
          render={({ field }) => (
            <Checkbox
              {...setCheckboxFieldProps(field, errors)}
              label="Hide offering card"
            />
          )}
        />
      </div>
    </>
  );
};

export default OfferingFields;
