import * as yup from 'yup';

import {
  BankAccountInput,
  CreateDwollaCustomerInput,
  VerifyBankAccountInput
} from '../../../__generated__';
import { SchemaShape } from '../../../types/yup';
import { email, yupString } from '../../../utils/validations';
import { documentIdTypes } from '../constants';

const documentIdTypesValues = documentIdTypes.map(
  documentType => documentType.value
);

export const accountSchema = yup
  .object<SchemaShape<CreateDwollaCustomerInput>>()
  .shape({
    businessName: yupString.required('Business name is required'),
    doingBusinessAs: yupString,
    ein: yupString
      .required('TIN or EIN is required')
      .test(
        'EIN is 9 digits',
        'EIN must be 9 digits',
        value => !value || value.replace(/\D/g, '').length === 9
      ),
    email: email
  });

export const bankAccountSchema = yup
  .object<SchemaShape<BankAccountInput>>()
  .shape(
    {
      accountNumber: yupString.when('id', {
        is: (val: null | string) => !val,
        then: () =>
          yupString
            .required('Account number is required')
            .min(4, 'Account number must be at least 4 digits')
      }),
      id: yupString.when('name', {
        is: (val: null | string) => !val,
        then: () => yupString.required('Bank account is required')
      }),
      name: yupString.when('id', {
        is: (val: null | string) => !val,
        then: () => yupString.required('Bank name is required')
      }),
      routingNumber: yupString.when('id', {
        is: (val: null | string) => !val,
        then: () =>
          yupString
            .required('Routing number is required')
            .test(
              'Routing number is 9 digits',
              'Routing number must be 9 digits',
              value => !value || value.replace(/\D/g, '').length === 9
            )
      }),
      type: yupString.when('id', {
        is: (val: null | string) => !val,
        then: () => yupString.required('Account type is required')
      })
    },
    [['id', 'name']]
  );

export const verifyBankAccountSchema = yup
  .object<SchemaShape<VerifyBankAccountInput>>()
  .shape({
    amount1: yupString.required('Amount 1 is required'),
    amount2: yupString.required('Amount 2 is required')
  });

export const editIdentityDocumentSchema = yup.object().shape({
  documentType: yupString
    .oneOf(documentIdTypesValues)
    .required('Document Type is Required'),
  file: yup.mixed().required('Document is Required')
});

export const moveDwollaCustomerBalanceSchema = yup.object().shape({
  destination: yupString.required('Destination funding source is required')
});
